* {
  // When define this font size you can use rem with this size
  // Example if you have a title class you can use font-size: 2em and that is same the 16px
  // and can use with other properties like: padding, margin and other
  // you can add * { font-size: 2rem } to your class for set 16px font-size to all
  font-size: 16px;
}
body {
  line-height: 1.3;
  @extend .font-size-14;
  min-width: 300px;
}
html, body, #root {
  height: 100%;
}
#root {
  display: flex;
  flex-direction: column;
}

// * {
//   outline: 1px solid red;
// }
.line-height-1 {
  line-height: 1;
}
.line-height-1-2 {
  line-height: 1.2;
}
.line-height-1-3 {
  line-height: 1.3;
}
.line-height-1-5 {
  line-height: 1.5;
}
.line-height-1-7-5 {
  line-height: 1.75;
}

h1,
h2,
h3,
h4,
h5,
h6 {

  margin-block-start: 0;
  margin-block-end: 0;
  @extend .font-family-title;
  @extend .line-height-1-3;
}
h1,h2,h3{
  font-weight: bold;
}
h2{
  font-size: 38px;
}
h3{
  font-size: 24px;
}
h4{
  @extend .text-blue;
  font-size: 20px;
}
@media (max-width: $max-width-tablet-brakepoint) {
  h2{
    font-size: 30px;
  }
}

p {
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}

a {
  display: inline-block;
  text-decoration: none;
  font-weight: 600;
}

hr {
  background-color: $gray-medium;
}

span{
  font-size: 16px !important;
}

.rouded {
  border-radius: 50%;
}

.lg-icon{
  width: 18px;
}

.text-start {
  text-align: left!important
}

.text-end {
  text-align: right!important
}

.text-center {
  text-align: center!important
}

@media(min-width: 768px) {
  .text-md-start {
    text-align: left!important
  }

  .text-md-end {
    text-align: right!important
  }

  .text-md-center {
    text-align: center!important
  }
}

