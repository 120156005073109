
@import "../../../../../../resources/scss/base/variables";
.HeaderComponent{
  width: 100%;
  z-index: 100;
  position: relative;
  margin-bottom: 6px;
  box-shadow: 0px 3px 24px -4px #D9D9D9;
  background-color: #004cc6;
  &.retail-design{
    background-color: $white;
  }
}

#logo {
  width: 152.68px;
}

@media (max-width: $max-width-mobile-brakepoint) {
  .HeaderComponent{
    margin-bottom: 0px;
  }
  #logo {
    width: 101.79px;
  }
}
