@import "variables";

.btn {
  @extend .font-family-title;
  outline: none !important;
  box-shadow: none !important;
}

.btn-gray {
  background-color: $gray-light;
  color: #738998 !important;
}

.btn-yellow {
  background-color: $yellow;
  color: $white !important;
}

Form{
  .form-group Label {
    margin-top: 1.15em;
    margin-bottom: 5px;
    color: $gray-darker;
    font-size: .875rem;
  }
  .form-group:first-of-type > Label{
    margin-top: 0;
  }
  Input.form-control, .input-group-text  {
    border: 1px solid $gray-light !important;
    background-color: $white;
    color: $gray-darker;
  }
  .af-input-group {
    .default-input{
      border-radius: 6px;
    }
    span {
      &.input-group-text{
        border-radius: 0 6px 6px 0 !important;
      }
    }
  }

  Input.form-control::placeholder {
    font-size: 1rem;
    font-weight: lighter;
    color: $gray-medium;
  }
  Input.form-control:focus{
    box-shadow: none !important;
  }
  .form-check {
    display: flex;
    align-items: center;
    position: relative;
  }
  .form-check Label {
    color: $blue-dark;
    padding-left: 0.5em;
  }
  .form-check-input[type=checkbox] {
    color: $blue;
    border-radius: 2px;
    border-color: $gray-light;
    box-shadow: none;
    position: absolute;
    width: 1.286em;
    height: 1.286em;
  }
  .form-check-input:checked {
    background-color: $blue;
    border-color: $blue;
  }
  .input-group .form-control{
    border-right: 0px !important;
  }
  .was-validated .form-control:invalid, .form-control.is-invalid{
    background-image: none !important;
  }
  .append-icon{
    color: $gray-darker;
  }
  .append-icon.invalid-feedback{
    color: $red;
    display: block;
    margin-top: 0;
  }

  .border-red{
    border-color: $red !important;
  }
}
.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: $red !important;
}
.form-button {
  width: 100% !important;
  max-width: 60%;
}
@media (max-width: $max-width-tablet-brakepoint) {
  .form-button{
    max-width: 100%;
  }
}
.form-button-icon {
  vertical-align: 0.125em;
  margin-right: 0.4em;
}
.invalid-feedback {
  color: $red;
  @extend .font-size-14;
}
.cursor-pointer {
  cursor: pointer !important;
}
