$blue-dark: #15257A;
$blue: #004CC6;
$blue-light: #0EA7E2;
$blue-lighter: #ddf2ff;
$blue-secondary: #8A92BD;
$orange: #e85e00;
$yellow: #FFAC1A;
$yellow-light: #ffe6a6;
$gray: #BFBFBF;
$gray-soft: #f5f5f5;
$gray-medium:#C5C5C5;
$gray-light: #D9D9D9;
$gray-lighter: #f5f8fa;
$gray-dark: #7e7e7e;
$gray-darker:#262626;
$gray-light-blue: #80a5e2;
$gray-light-blue-1: #E5EDF9;
$white: white;
$black: black;
$font-family-base: 'Source Sans Pro', arial;
$font-family-title: 'Source Sans Pro', arial;
$green: #389E0D;
$red: #E61F37;
$red-light: #FDE9EB;
$orange-dark: #FF3F00;
$spacer: 15px;

$theme-colors: (
  "primary": $blue,
  "secondary": $blue-dark,
  "success": $blue-light,
  "info": $blue-light,
  "danger": $red
);

$max-width-mobile-brakepoint: 767px;
$max-width-tablet-brakepoint: 1269px;
$lg-breakpoint: 992px;

$enable-reduced-motion: false;
