@import '../../../../resources/scss/base/variables';
.error-bag-wrapper{
    width: 100%;
    transition: all 0.3s ease-in-out;
    opacity: 1;
    position: relative;
}
.error-bag{
    opacity: 1;
    width: 100%;
    background-color: $red-light;
    color: $gray-darker;
    padding: 0.5em;
    border-radius: 5px !important;
    z-index: 0 !important;

    @media (max-width: 850px) {
        padding: 0.5em;
    }
  }

.error-bag .anticon{
    font-size: 2em !important;
    color: $red;
}