.decorator-container{
  .decorator {
    width: 560px;
    height: 357px;
    display: none;
    &.first {
      bottom: 3rem;
      left: -6rem;
      z-index: -1;
      position: absolute;
      clear: both;
    }
    &.second {
      bottom: 0;
      left: -2rem;
      z-index: -1;
      position: absolute;
      clear: both;
    }
    @media (min-width: 992px) {
      display: block;
    }
  }
}
