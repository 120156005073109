
@import '../../../../resources/scss/base/variables';
.MessageComponent{
    width: 100vw;
    height: fit-content;
}
.font-size-34{
    font-size: 34px !important;
}
.box-message{
    max-width:438px;
    border-radius: 5px;
}
.car-outlined{
    width: 64px;
    height: 64px;
    margin-right: 20px;
}
.car-outlined:last-child{
    margin-right: 0px !important;
}
.spinner{
    width: 120px;
    height: 120px;
    border-width: 6px;
}
.MessageComponent .btn-primary{
    width: 100%;
    max-width: 250px;
    line-height: 2;
}

@media (max-width: 1269px) {
    .MessageComponent{
        max-width: 100vw;
    }
    .MessageComponent .btn-primary{
        max-width: 438px;
    }
}