.loading-backdrop{
    background-color: rgba($color: #ffff, $alpha: 0.6);
    width: 100%;
    height: 100%;
    position: absolute;
    justify-content: center;
    display: flex;
    align-content: center;
    align-items: center;
    z-index: 900;
    border-radius: 7px;
  }
  .loading-backdrop-fixed{
    background-color: rgba($color: #ffff, $alpha: 0.6);
    width: 100%;
    height: 100%;
    position: fixed;
    justify-content: center;
    display: flex;
    align-content: center;
    align-items: center;
    z-index: 90;
    border-radius: 7px;
    top: 0;
    left: 0;
  }
  