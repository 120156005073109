.ConfirmSignUpComponent{
    width: 100vw;
    max-width:438px;
    height: fit-content;
}

.ConfirmSignUpComponent .form-button {
    width: 100% !important;
    max-width: 341px;
}

@media (max-width: 700px) {
    .ConfirmSignUpComponent{
        max-width: 100vw;
    }
    .ConfirmSignUpComponent .form-button {
        max-width: 100%;
    }
  }