// Fonts Color
.text-black {
  color: $black;
}
.text-blue-dark {
  color: $blue-dark;
}
.text-blue {
  color: $blue;
}
.text-blue-light {
  color: $blue-light;
}
.text-blue-lighter {
  color: $blue-lighter;
}
.text-blue-secondary {
  color: $blue-secondary;
}

.text-orange {
  color: $orange;
}
.text-yellow {
  color: $yellow;
}
.text-orange-dark {
  color: $orange-dark;
}
.text-white {
  color: $white;
}
.text-red {
  color: $red;
}
.text-gray-dark {
  color: $gray-dark;
}
.text-green {
  color: $green;
}

// Fonts weight
.font-bold {
  font-weight: bold;
}
.font-lighter {
  font-weight: 400;
}
.text-decoration {
  text-decoration: underline;
}


// Text sizes
.font-size-14{
  font-size: 14px;
}
