.font-family-base {
  font-family: $font-family-base;
}
.font-family-title {
  font-family: $font-family-title;
}
body {
  @extend .font-family-base;
}

$font-size: 12;

@while $font-size <= 28 {
  .font-size-#{$font-size} {
    font-size: #{$font-size}px !important;
  }
  $font-size: $font-size + 1;
}