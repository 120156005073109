@import "variables";

@each $key,$color in $theme-colors {
  .btn-#{$key}:disabled,
  .btn-#{$key}.disabled,
  .btn-#{$key}[disabled] {
    color: $gray !important;
    background-color: $gray-soft !important;
    border: 1px solid $gray-light !important;
    cursor: not-allowed;
  }
}

.btn-primary {
  height: 45px !important;
  font-size: 16px;
  border-radius: 12px !important;
  border: none !important;
  box-shadow: none !important;
  color: white !important;
  font-weight: bold !important;
  letter-spacing: 0.7px !important;
  @include button-variant($orange-dark, darken($orange-dark, 7.5%), darken($orange-dark, 10%), lighten($orange-dark,25%), lighten($orange-dark, 10%), darken($orange-dark,30%));
}
.btn-outline-primary{
  font-weight: 600;
  height: 45px !important;
  font-size: 16px !important;
  border-radius: 10px !important;
  @include button-variant($white, $blue, $blue, $white, $gray-light-blue, $gray-light-blue, $white, $blue, $blue);
}
a.btn-outline-primary{
  line-height: 1.75;
}
