.NotFoundComponent{
    width: 100vw;
    height: fit-content;
}
.NotFoundComponent .btn-primary{
    width: 100%;
    max-width: 250px;
    line-height: 2;
}

@media (max-width: 1269px) {
    .NotFoundComponent{
        max-width: 100vw;
    }
    .NotFoundComponent .btn-primary{
        max-width: 438px;
    }
}

