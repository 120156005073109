.PasswordChangeComponent{
    width: 100vw;
    max-width:438px;
    height: fit-content;
}

.PasswordChangeComponent .form-button {
    width: 100% !important;
    max-width: 362px;
}

@media (max-width: 768px) {
    .PasswordChangeComponent{
        max-width: 100vw;
    }
    .PasswordChangeComponent .form-button {
        max-width: 100%;
    }
}