@import "../../../../../../resources/scss/base/variables";
.loginComponent-container{
    &.retail-design {
        max-width:551px;
        height: fit-content;
        @media (min-width: 768px) {
            max-width:385px;
        }
        .LoginComponent{
            .login-title{
                font-size: 1rem;
            }
            box-shadow: 0 3px 24px -4px rgba(0, 0, 0, 0.10);
            padding: 1.5rem;
            border: 1px solid $gray-light-blue-1;
            border-radius: 24px;

        }

        .LoginComponent .form-button {
            max-width: 316px;
        }
    }
}


.LoginComponent{
    &.default{
        width: 100vw;
        max-width:551px;
        height: fit-content;
        .form-button {
            width: 100% !important;
            max-width: 316px;
            @media (max-width: 1269px) {
                max-width: 100%;
            }
        }
        @media (max-width: $max-width-mobile-brakepoint) {
            .LoginComponent{
                width: 100vw;
                max-width: 100vw;
            }
            body{
                background-color: white !important;
            }
        }
    }
}

