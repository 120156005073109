@import "../../../../../../resources/scss/base/variables";
.SignUpComponent{
    &.retail-design{
        margin: auto;
        max-width: 588px;
        height: fit-content;
        border-radius: 1.5rem;
        padding: 1.5rem;
        border: 1px solid $gray-light-blue-1;
        box-shadow: 0 3px 24px -4px $gray-light;
        background-color: white;
        .btn{
            max-width: 100%;
        }
        form {
            .sign-up-name-group{
                @media (min-width: $lg-breakpoint) {
                    display: flex;
                    justify-content: space-between;
                    gap: 1rem;
                    label {
                        margin: 0 0 5px 0;
                    }
                    .form-group {
                        width: 50%;
                    }
                }
            }
            .form-group{
                .input-group{
                    input {
                        border-radius: 12px;
                    }
                    span {
                        border-radius: 0 12px 12px 0 !important;
                    }
                }
            }
        }
    }

    &.default {
        display: flex;
        justify-content: center;
        width: 100vw;
        max-width: 822px;
        height: fit-content;
        form{
            max-width:438px;
        }
        .btn {
            width: 100% !important;
            max-width: 341px;
        }
        @media (max-width: 767px) {
            max-width:438px;
            .btn {
                max-width: 100%;
            }
        }
    }
}
