@import "../../../../resources/scss/base/variables";
.HeaderContainer{
  width: 100%;
  z-index: 100;
  position: relative;
}

@media (max-width: $max-width-mobile-brakepoint) {
  .HeaderContainer{
    margin-bottom: 0px;
  }
}