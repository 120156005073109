@import "../../../../resources/scss/base/variables";

@media (max-width: $max-width-tablet-brakepoint) {
    .HeadingContainer {
        margin-top: inherit;
        padding-bottom: 0.5em;
    }

    .HeadingComponent p {
        font-size: 16px;
    }
}

.HeadingContainer {
    &.retail-design {
        margin-top: unset;

        .HeadingComponent {
            width: unset;
            max-width: 551px;
            height: fit-content;
            color: $black;

            @media (min-width: $max-width-mobile-brakepoint) {
                text-align: center;
            }

            @media (min-width: $lg-breakpoint) {
                text-align: left;
            }

            .title {
                // font-size: 2.375rem;
                font-size: 38px;
                font-weight: 700;
                line-height: 46px;

                @media (max-width: $max-width-mobile-brakepoint) {
                    font-size: 34px;
                    line-height: 42px;
                }
            }

            .list {
                .list-title {
                    font-size: 1.125rem;
                    padding-bottom: 1rem;
                }
            }

            .li-class-check-pink {
                padding: 0;
                list-style-type: none;

                li {
                    display: flex;
                    gap: 1rem;
                    align-content: center;
                    font-size: 1rem;
                    font-weight: lighter;
                    padding-bottom: 1rem;

                    p {
                        width: 90%;
                    }
                }
            }

            .li-class-check-pink li:before {
                content: "";
                width: 1rem;
                background-image: url("../../../../resources/icons/check-icon-pink.svg");
                background-repeat: no-repeat;
                background-position: center center;
            }
        }
    }

    .HeadingComponent h2 {
        margin-bottom: 0.5em;
    }

    .HeadingComponent .content {
        line-height: 28px;
        font-weight: 400;
    }
}


.HeadingContainer {
    margin-top: 6em;
}

.HeadingComponent {
    width: 100vw;
    max-width: 551px;
    height: -moz-fit-content;
    height: fit-content;
    color: $blue;
}

.HeadingComponent h2 {
    margin-bottom: 0.5em;
}

.HeadingComponent .separator {
    width: 51px;
    height: 0px;
    border: 1px solid #004CC6;
    transform: rotate(90deg);
    display: inline-flex;
    margin-left: -10px;
    margin-right: 10px;
}

.HeadingComponent .title {
    line-height: 28px;
    font-weight: 700;
}

.HeadingComponent .content {
    line-height: 28px;
    font-weight: 400;
}

.HeadingComponent .logo-autofact {
    max-height: 51px;
    margin-top: -20px;
    margin-left: -51px;

}

.HeadingComponent .logo-autopress {
    max-height: 51px;
    max-width: 139px;
    margin-top: 10px;
}

.heading-box-padding {
    padding: 4em 1em 3em 1em;
}

@media (max-width: $max-width-mobile-brakepoint) {
    .heading-box-padding {
        padding: 1.15em 1.15em 3.857em 1.15em;
    }
}

.HeadingComponent p {
    font-size: 20px;
}

@media (max-width: $max-width-tablet-brakepoint) {
    .HeadingContainer {
        margin-top: inherit;
        padding-bottom: 0.5em;
    }

    .HeadingComponent p {
        font-size: 16px;
    }
}