@import "_bootstrap";
@import "_variables";
@import "_base-fonts";
@import "_fonts";
@import "_base-colors";
@import "_animations";
@import "_form";
@import "_buttons";
@import "_app";
html {
  scroll-behavior: smooth;
}

body {
  color: $black;
  background-color: $white;
  background-position: left center;
  height: inherit;
  background-attachment: fixed;
  .container-af{
    position:relative;
    padding: 1em;
    @media (min-width: 476px){
      width:100%
    }
    @media (min-width: 768px){
      width:720px;
      margin-left:auto;
      margin-right:auto;
      max-width:100%
    }
    @media (min-width: 992px){
      padding-right: 1em;
      padding-left: 1em;
      width:960px;
      max-width:100%
    }
    @media (min-width: 1200px){
      padding-right:1em;
      padding-left:1em;
      width:1140px;
      max-width:100%
    }
    @media (min-width: 1400px){
      width:1340px;
      max-width:100%
    }
  }
}
@media (max-width: 1024px) and (orientation:portrait){
  body {
    background: $white;
  }
}
@media (max-width: $max-width-mobile-brakepoint) {
  body {
    background: $white;
  }
}

.pointer {
  cursor: pointer;
}

.big-spinner{
  width: 50px;
  height: 50px;
  border-width: 5px;
  border-color: $blue-light;
  border-right-color: transparent !important;
}
.xl-spinner{
  width: 60px;
  height: 60px;
  border-width: 5px;
  border-color: $blue-light;
  border-right-color: transparent !important;
}

.content-wrapper{
  position: relative;
  display: flex !important;
  justify-content: space-around !important;
  width: 100%;
  height: fit-content;
  padding-top: 2em;
}

@media (max-width: 1500px) {
  .content-wrapper{
    padding-top: 2em;
    padding-bottom: 2em;
  }
}
@media (max-width: 1024px) {
  .content-wrapper{
    display: block !important;
  }
}
@media (max-width: 800px) {
  .content-wrapper{
    padding-top: 2em;
    padding-bottom: 2em;
  }
}
@media (max-width: $max-width-mobile-brakepoint) {
  .content-wrapper{
    padding-top: 0em;
    padding-bottom: 0em;
    display: block !important;
  }
}
.content-container{
  justify-content: center;
  display: flex;
  align-self: center;
  position: relative;
  z-index: 90;
  width: fit-content;
  padding: 0 3em 0 3em;
  border-radius: 12px !important;
  animation: fadeIn; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 0.5s; /* don't forget to set a duration! */
  margin: auto;
}
@media (max-width: $max-width-tablet-brakepoint) {
  .content-container{
    width: 100vw;
    padding: 0;
  }
}

.box-padding{
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 3em;
  padding-bottom: 3em;
}
@media (max-width: $max-width-mobile-brakepoint) {
  .box-padding{
    padding: 1.15em 1.15em 3.857em 1.15em;
  }
}

.close-icon-container{
  z-index: 99999;
  cursor: pointer;
  position: absolute;
  right: 2em;
  visibility: hidden;
}
@media (max-width: $max-width-mobile-brakepoint) {
  .close-icon-container{
    visibility: visible;
    right: 1em;
  }
}
.close-icon{
  cursor: pointer;
  color: $gray-light-blue;
  &:hover{
    color: $blue;
  }
}

.text-wrapper{
  position: relative;
}

.p-absolute{
  position: absolute !important;
}

.border-radius-12-px{
  border-radius: 12px;
}
