.text-blue-dark {
  color: $blue-dark;
}
.text-blue {
  color: $blue;
}
.text-blue-light {
  color: $blue-light;
}
.text-blue-lighter {
  color: $blue-lighter;
}
.text-blue-secondary {
  color: $blue-secondary;
}

.text-orange {
  color: $orange;
}
.text-yellow {
  color: $yellow;
}
.text-orange-dark {
  color: $orange-dark;
}
.text-white {
  color: $white;
}
.text-red {
  color: $red;
}
.text-gray-dark {
  color: $gray-dark;
}
.text-green {
  color: $green;
}

.bg-blue {
  background-color: $blue;
}
.bg-blue-dark {
  background-color: $blue-dark;
}
.bg-blue-light {
  background-color: $blue-light;
}
.bg-blue-lighter {
  background-color: $blue-lighter;
}
.bg-gray-light {
  background-color: $gray-light;
}
.bg-gray-soft {
  background-color: $gray-soft;
}
.bg-gray-lighter {
  background-color: $gray-lighter;
}
.bg-yellow {
  background-color: $yellow;
}
.bg-yellow-light {
  background-color: $yellow-light;
}
.bg-white {
  background-color: $white !important;
}
.bg-orange {
  color: $orange;
}
.bg-orange-dark {
  background-color: $orange-dark;
}
.bg-gray-dark {
  background-color: $gray-dark;
}
.bg-green {
  background-color: $green;
}

.opacity-9 {
  opacity: 0.9;
}
.opacity-8 {
  opacity: 0.8;
}
.opacity-1 {
  opacity: 1;
}
.opacity-0 {
  opacity: 0;
}
