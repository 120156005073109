.modal-mail-verification-component{
    max-width: 384px;
    h3 {
        font-size: 18px;
    }
    .modal-content{
        border-radius: 24px !important;
    }
    .mail-verification-button{
        width: 100%;
    }
}

.MailVerificationComponent{
    height: fit-content;
    padding: 1.5rem 0;
    .green-check-icon{
        svg {
            width: 32px;
            height: 32px;
        }
    }
}
