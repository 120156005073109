@import "../../../../../../resources/scss/base/variables";

.EmailSendComponent{
    padding: 1.5rem;
    border: 1px solid $gray-light-blue-1;
    box-shadow: 0 3px 24px -4px $gray-light;
    background-color: white;
    border-radius: 12px;
    max-width: 384px;

    h1 {
        font-size: 1.125rem;
        line-height: 1.625rem;
    }
}

.EmailSendComponent .form-button {
    width: 100% !important;
    max-width: 362px;
}

