@import "../../../../../../resources/scss/base/variables";
.FooterComponent{
  width: 100%;
  margin-top: auto;
}

.secondary-logo{
  width: 120px;
}

.rrss-links {
  a{
   margin-right: 20px;
  }
  a:last-child{
    margin: 0;
  }
}
